/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Category } from '@/components/content/ShopByCategory';
import { useCategoryRecommendation } from '@/data/Content/CategoryRecommendation';
import { useLocalization } from '@/data/Localization';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { categoryModuleHeadingSX, categoryModulePuchOutSX, categoryModuleRegularSX } from '@/components/content/CategoryRecommendation/styles/heading';
import { useJJKUser } from '@/data/User';

const emptyProperties = {} as WidgetProperties;

export const CategoryRecommendation: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { categories, clickAction, loading } = useCategoryRecommendation(emsName);
	const category = useLocalization('Category');
	const punchOutUser = useJJKUser();
	const isPunchOut = punchOutUser?.user?.isPunchoutUser;

	return (
		<Box sx={isPunchOut ? categoryModulePuchOutSX : categoryModuleRegularSX}>
			<Box sx={{ maxWidth: '1052px', margin: '0 auto', paddingTop: '16px', paddingBottom: '16px', marginBottom: '40px' }}>
				<Typography variant="h2" sx={categoryModuleHeadingSX}>{category.ShopByCategoryText.t()}</Typography>
				<Grid container spacing={2} alignItems='stretch'>
					{loading
						? 'Loading...'
						: categories.map(({ id }) => (
							<Grid
								key={id}
								item
								xs={6}
								md={6}
								lg={12 / 5}
								id={`categoryRecommendation_div_2_${id}`}
								data-testid={`categoryRecommendation_div_2_${id}`}
							>
								<Category id={id} clickAction={clickAction(id)} />
							</Grid>
						))}
				</Grid>
			</Box>
		</Box>
	);
};
