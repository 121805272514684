/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomProgressIndicator } from '@/components/blocks/CustomProgressIndicator';
import { CatalogEntryRecommendation } from '@/components/content/CatalogEntryRecommendation';
import { CategoryRecommendation } from '@/components/content/CategoryRecommendation';
import { ContentCarousel } from '@/components/content/ContentCarousel';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { globalEspotContainerSX } from '@/components/content/EMarketingSpot/styles/globalEspotContainer';
import { useEMarketingSpot } from '@/data/Content/EMarketingSpot';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import { Stack, useTheme } from '@mui/material';
import { FC } from 'react';

const emptyProperties = {} as WidgetProperties;

const DisplayEmarketingSpot: FC<{ id: ID; properties?: WidgetProperties; emsName: ID }> = ({
	id,
	properties,
	emsName,
}) => {
	const { data, loading } = useEMarketingSpot(emsName);
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	if (!loading && !data.hasContent && !data.hasCategory && !data.hasCatEntry) {
		return null;
	}
	return loading ? (
		<CustomProgressIndicator />
	) : (
		<Stack sx={globalEspotContainerSX} gap={contentSpacing}>
			{data.hasContent ? (
				data.hasContentCarousel ? (
					<ContentCarousel id={id} properties={properties} />
				) : (
					<ContentRecommendation id={id} properties={properties} />
				)
			) : null}
			{data.hasCategory ? <CategoryRecommendation id={id} properties={properties} /> : null}
			{data.hasCatEntry ? <CatalogEntryRecommendation id={id} properties={properties} /> : null}
		</Stack>
	);
};

export const EMarketingSpot: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	return emsName === '' ? (
		null
	) : (
		<DisplayEmarketingSpot id={id} properties={properties} emsName={emsName} />
	);
};
