/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material/styles';

export const categoryModuleHeadingSX: SxProps<Theme> = (theme) => ({
	color: 'heading.dark',
    marginBottom: '32px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
		  margin: '16px 0 24px 0',
      fontSize: '26px',
	},
});

export const categoryModulePuchOutSX: SxProps<Theme> = () => ({
	background: '#fff',
    marginLeft: 'calc((100% - 100vw) / 2)',
    width: '100vw',
    padding: '40px 0',
});

export const categoryModuleRegularSX: SxProps<Theme> = () => ({
	background: '#fafafb',
});
